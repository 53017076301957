.background-logo {
    position: absolute;
    bottom: 8%;
}

.slogan-container {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.slogan {
    font-size: 50px;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(150, 150, 150, 0.8);
}

.margin-40 {
    margin: 40px;
}

.display-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; /* Add this to wrap items on smaller screens */
}

.display-flex-row > div {
    margin: 10px; /* Add some margin between items */
}

.buttons {
    text-align: center;
}

.mt-20 {
    margin-top: 20px;
}

.ml-20 {
    margin-left: 20px;
}

/* Add media queries for different screen sizes */
@media (max-width: 768px) {
    .slogan {
        font-size: 30px;
    }
    
    .margin-40 {
        margin: 20px;
    }
    
    .display-flex-row {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: center;
    }
}
