.post-listing {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.post-listing h2 {
    text-align: center;
    margin-bottom: 20px;
}

.post-listing-form .form-group {
    margin-bottom: 15px;
}

.post-listing-form .form-control {
    width: 100%;
}

@media (max-width: 768px) {
    .post-listing {
        padding: 10px;
    }

    .post-listing-form .form-group {
        margin-bottom: 10px;
    }
}
