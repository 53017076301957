.margin-tlr {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
}

.center {
    text-align: center;
}

.mt-20 {
    margin-top: 20px;
}

.display-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.display-flex-col {
    display: flex;
    flex-direction: column;
}

.rounded-box {
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
}

@media (max-width: 768px) {
    .margin-tlr {
        margin-left: 20px;
        margin-right: 20px;
    }

    .display-flex-row {
        flex-direction: column;
    }

    .display-flex-col {
        margin-bottom: 20px;
    }
}
